<template>
  <el-card title="添加要闻">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="80px"
    >
      <b-row>
        <b-col md="3">
          <el-form-item
            label="标题"
            prop="title"
            :rules="[ { required: true, message: '标题不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.title"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item label="发布人">
            <el-input v-model="form.releaseUser" />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item label="发布日期">
            <el-date-picker
              v-model="form.releaseDate"
              placement="bottom-start"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="简介">
            <el-input
                v-model="form.introduction"
                type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item
            label="首图图片"
            prop="firstImageUrl"
            :rules="[ { required: true, message: '首图图片不能为空', trigger: 'change' }]"
          >
            <el-upload
              class="avatar-uploader"
              :action="uploadImgServe"
              :headers="headers"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img
                v-if="form.firstImageUrl"
                :src="form.firstImageUrl"
                class="avatar-img"
               alt="首图">
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              />
            </el-upload>
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item
            label="来源"
          >
            <el-input
              v-model="form.source"
              v-input-filter
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item label="来源地址">
            <el-input
              v-model="form.sourceUrl"
              v-input-filter
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="内容">
            <div
              class="editor-wrap"
              style="border: 1px solid #ccc"
            >
              <EditorVue
                ref="editorVue"
                :content.sync="form"
              />
            </div>
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
      <el-button
        type="primary"
        @click="preview"
      >
        预 览
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { getNewsInfo, saveOrUpdateNews } from '@/api/website/website'
import { success, error, formatDateStr } from '@core/utils/utils'
import EditorVue from '@core/components/editor-vue/EditorVue.vue'
export default {
  components: { EditorVue },
  data() {
    return {
      uploadImgServe: `${window.g.baseUrl}/website/news/upload_img`,
      headers: {
        Accept: 'text/x-json',
        'm-token': localStorage.getItem('m-token'),
      },
      form: {
        id: 0,
        title: '',
        introduction: '',
        firstImagePath: '',
        firstImageUrl: '',
        releaseUser: '',
        source: '',
        sourceUrl: '',
        releaseDate: undefined,
        content: '',
        contentImages: [],
      },
      newsType: Number(this.$route.query.t), // 1 法律 2 行业
      loading: false,
      uploadLoading: false,
      navTitle: '',
      navPath: '',
    }
  },
  created() {
    this.navTitle = this.newsType === 2 ? '行业洞察管理' : '法规要闻管理'
    this.navPath = this.newsType === 2 ? '/industry' : '/lawnews'
    this.form.releaseDate = formatDateStr(new Date(), 'yyyy-MM-DD HH:mm:ss')
    if (this.$route.query.id) {
      this.form.id = Number(this.$route.query.id)
      this.getNewsInfo()
    }
  },
  mounted() {
    this.navActiveInit(1, this.navTitle, '官网管理', this.navPath)
  },
  destroyed() {
    this.navActiveInit(2, this.navTitle, '官网管理', this.navPath)
  },
  methods: {
    handleAvatarSuccess(res, file) {
      const resData = res.data
      if (res.code === 0) {
        this.$refs.form.clearValidate(['firstImagePath'])
        this.form.firstImagePath = resData.savePath
        this.form.firstImageUrl = resData.accessPath
        success(res.msg)
      } else {
        error(resData.msg)
      }
    },
    preview() {
      // 没保存之前保存在浏览器缓存
      if (this.form.content) {
        localStorage.setItem('content', this.form.content)
        localStorage.setItem('title', this.form.title)
        localStorage.setItem('releaseDate', this.form.releaseDate)
        const routeUrl = this.$router.resolve({
          path: '/news',
          query: {
            s: this.form.source,
            u: this.form.sourceUrl
          }
        })
        window.open(routeUrl.href, '_blank')
      } else {
        error('内容为空，无法预览')
      }
    },
    back() {
      if (this.newsType === 2) {
        this.$router.push('/industry')
      } else {
        this.$router.push('/lawnews')
      }
    },
    getNewsInfo() {
      getNewsInfo(this.form.id)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.form = { ...resData.data }
          }
        })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.form.id === '') {
            delete this.form.id
          }
          this.form.contentImages = this.$refs.editorVue.contentImages
          saveOrUpdateNews(this.newsType, this.form)
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.back()
              } else {
                error(resData.msg)
              }
            })
          this.loading = false
        } else {
          return false
        }
        return true
      })
    },
  },
}
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    .avatar-img {
        width: 200px;
        height: 150px;
        display: block;
    }
</style>
